import React from 'react'
import { Container } from '../../components/elements'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import PimForm from '../../components/forms/PimSignUp'
import Newsletter from '../../components/Newsletter'

const PimContactPage = () => {
  const breadcrumbs = [
    {
      name: 'PIM',
      uri: '/pim',
    },
    {
      name: 'Contact - PIM',
      uri: '/pim/contact',
    },
  ]

  return (
    <Page className={`p-sign-up p-forManufacturers has-fingerPrints`}>
      <SEO title="Contact - PIM" />
      <Schema breadcrumbs={breadcrumbs} />

      <section className="c-section c-section--pageHeader">
        <Container className="c-section__outer">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <span className="c-pageHeader__title">
                    <strong>PIM</strong>
                  </span>
                </div>
                <div className="col-12 col-lg-9">
                  <h1 className="c-pageHeader__intro">
                    PIM connector contact form
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-3">
                  <PimForm formName="PIM Sign Up Form" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default PimContactPage
