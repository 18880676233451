import React from 'react'
import PropTypes from 'prop-types'

import Form from './partials/NetlifyForm'
import Submit from './partials/Submit'

const PimSignUpForm = ({ formName }) => {
  return (
    <Form
      className="c-contactUsForm"
      formName={formName}
      successPage="/pim/success"
    >
      <p className="mb-3 pb-3">
        Fill out the below form to inform Erudus and Foodservice Online of your
        interest in Erudus Connector for Akeneo PIM, and one of the food service
        online team will be in touch with you shortly.
      </p>

      <div className="form-group row">
        <label
          htmlFor="name"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Your name*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="email"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Your email*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="company"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Company name*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="text"
            className="form-control"
            id="company"
            name="company"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="telephone"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Phone number*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="tel"
            className="form-control"
            id="telephone"
            name="telephone"
            required
          />
        </div>
      </div>

      <Submit />
    </Form>
  )
}

PimSignUpForm.propTypes = {
  formName: PropTypes.string.isRequired,
}

export default PimSignUpForm
